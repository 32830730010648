h2#sms-title {
  text-align: center;
}

div#sms-form-holder {
  width: 50vw;
  margin: 0px auto;
  background-color: white;
  padding: 4vh 0px;
}

div#sms-form-holder h5 {
  text-align: center;
  font-size: 22px;
  color: #28573b;
  font-weight: 500;
  margin: 0px auto;
  width: 80%;
}

input#smscode {
  border: none;
  border-bottom: 2px solid rgba(47, 79, 79, 0.466);
  width: 280px;
  display: block;
  margin: 0px auto;
  font-size: 55px;
  letter-spacing: 15px;
  text-align: center;
  margin-top: 5vh;
}

div#sms-form-holder button {
  margin: 0px auto;
  display: block;
  margin-top: 10vh;
}

div#sms-form-holder h5#sms-error {
  color: rgb(230, 155, 44);
  font-size: 15px;
  font-weight: 500;
  width: 80%;
  margin-top: 20px;
}

h3#success-message {
  font-family: "DM Sans", sans-serif;
  color: #28573b;
  width: 80%;
  margin: 0px auto;
}

/* ------------------------------------------------ */

@media (max-width: 768px) {
  div#sms-form-holder {
    width: 100%;
    border-radius: 3px;
  }
}
