.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendingMachineContainer {
  width: 331px;
  height: 486px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.5);
}

#vitalayLogo {
  width: 80px;
  position: absolute;
  top: 13px;
}

.selectedProduct {
  width: 66px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 70px;
  left: 255px;
  z-index: 50;
}

.button {
  opacity: 1;
  width: 46px;
  height: 46px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d8fac4;
  border-radius: 50%;
  top: 140px;
  left: 265px;
  z-index: 50;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.led {
  width: 3px;
  height: 3px;
  position: absolute;
  background: #eb3410;
  border-radius: 50%;
}

.ledSelected {
  background: #96ff14;
}

.led1 {
  top: 74px;
  left: 59px;
}

.led2 {
  top: 74px;
  left: 140px;
}

.led3 {
  top: 154px;
  left: 59px;
}

.led4 {
  top: 154px;
  left: 140px;
}

.led5 {
  top: 234px;
  left: 59px;
}

.led6 {
  top: 234px;
  left: 140px;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  transform: scale(0.9);
}

.buttonText {
  padding-bottom: 7px;
  padding-left: 2px;

  font-size: 35px;
  color: #05607c;
  text-align: center;
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.textContainer {
  font-size: 15px;
  color: #96ff13;
  text-align: center;
  font-family: "VT323", sans-serif;
  line-height: 13px;
}

.bottle {
  width: 30px;
  position: absolute;
  z-index: 50;
  cursor: pointer;
  opacity: 0.8;
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.bottleSelected {
  opacity: 1;
}
.bottle:hover {
  opacity: 1;
}

.bottleDrop1 {
  animation: drop1 0.8s ease-out;
  -moz-animation: drop1 0.8s; /* Firefox */
  -webkit-animation: drop1 0.8s ease-in; /* Safari and Chrome */
}

@keyframes drop1 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(-30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}
@-moz-keyframes drop1 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(-30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}
@-webkit-keyframes drop1 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(-30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}

.bottleDrop2 {
  animation: drop2 0.8s ease-out;
  -moz-animation: drop2 0.8s; /* Firefox */
  -webkit-animation: drop2 0.8s ease-in; /* Safari and Chrome */
}

@keyframes drop2 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}
@-moz-keyframes drop2 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}
@-webkit-keyframes drop2 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}

.formosa {
  top: 10px;
  left: 45px;
  z-index: 99;
}

.bali {
  top: 10px;
  left: 126px;
  z-index: 99;
}
.formosa {
  top: 10px;
  left: 45px;
  z-index: 99;
}

.jasmin {
  top: 90px;
  left: 45px;
  z-index: 95;
}

.marocain {
  top: 90px;
  left: 126px;
  z-index: 95;
}

.rio {
  top: 170px;
  left: 45px;
  z-index: 90;
}

.limonade {
  top: 170px;
  left: 126px;
  z-index: 90;
}

.bottleArea {
  width: 200px;
  height: 261px;
  position: absolute;
  left: 22px;
  top: 74px;
  overflow: hidden;
}

#PUSH {
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.infoContainer{
  position: absolute;
  margin-top: 6px;
  margin-left: 35px;
}

.infoBox{
  max-width: 18px;
  opacity: 1;
  z-index: 9999999999999999;
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.infoBox:hover{
  opacity: 0.3;
}

/* ------------------------------------------------   */

@media (max-width: 768px) {
  .vendingMachineContainer {
    transform: scale(1.5);
  }
}

@media (max-width: 568px) {
  .vendingMachineContainer {
    transform: scale(0.9);
  }
}
