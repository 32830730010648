div#home-split {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  height: 85vh;
}

div#left-split {
  width: 50%;
}

div#left-split h1 {
  font-family: "DM Sans", sans-serif;
  font-size: 46px;
  line-height: 55px;
  color: #05607c;
  margin-top: 5vh;
  margin-bottom: 0px;
}

div#left-split h4 {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  color: #9b9b9b;
  font-size: 19px;
  margin-top: 1vh;
  padding-right: 10vw;
  line-height: 24px;
  margin-bottom: 0px;
}

div#left-split ul {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #628871;
  margin: 0px;
  margin-top: 2vh;
}

div#left-split li {
  padding: 4px;
}

div#left-split span.important {
  font-weight: 700;
  color: #28573b;
  text-decoration: underline;
}

div#bill-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 50vh;
}

div#right-split {
  width: 50%;
}

table button.increment {
  background-color: rgb(170, 223, 202);
  display: inline;
  margin: 2px;
  border-radius: 10px;
  border: none;
  height: 20px;
  width: 20px;
}

table button.increment:focus {
  outline: none;
}

table button.increment:hover {
  cursor: pointer;
}

table button.increment:active {
  transform: scale(0.98);
}

h5#empty-cart {
  margin: 20px 0px;
  color: #05607cb0;
}

h5#prompter {
  color: rgb(230, 155, 44);
  font-size: 13px;
  font-weight: 500;
  width: 70%;
}

/* ------------------------------------------------   */

@media (max-width: 768px) {
  div#home-split {
    flex-direction: column-reverse;
    height: auto;
  }

  div#left-split {
    width: 100%;
  }

  div#left-split h1 {
    font-size: 36px;
    line-height: 37px;
    margin-top: 2vh;
  }

  div#left-split h4 {
    color: #9b9b9b;
    font-size: 17px;
    margin-top: 1vh;
    padding-right: 0vw;
  }

  div#left-split ul {
    font-size: 14px;
    margin: 0px;
    margin-top: 2vh;
    margin-bottom: 4vh;
  }

  div#left-split li {
    padding: 2px;
  }

  div#bill-center {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  table button.increment {
    background-color: rgb(170, 223, 202);
    display: inline;
    margin: 2%;
    border-radius: 5px;
    height: 20px;
    width: 45%;
  }

  h5#prompter {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }

  div#right-split {
    width: 100%;
    height: 900px;
  }
}

@media (max-width: 568px) {
  div#right-split {
    width: 100%;
    height: 500px;
  }
}
