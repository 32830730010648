div#form-holder {
  background-color: white;
  width: 75vw;
  padding: 35px;
  margin-bottom: 30px;
  display: flex;
  border-radius: 3px;
}

div#right-form {
  flex: 50;
}

div#left-form {
  flex: 50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

div#ticket-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

button#back-button {
  width: 45%;
}

button#back-button-mobile {
  display: none;
}

div#checkout-ticket {
  width: 80%;
}

button#order-button {
  width: 80%;
}

h5#checkout-prompt {
  color: rgb(230, 155, 44);
  font-size: 13px;
  font-weight: 500;
  width: 70%;
  width: 80%;
}

/* ------------------------------------------------ */

@media (max-width: 768px) {
  div#form-holder {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    padding-bottom: 3vh;
    background-color: transparent;
  }

  div#ticket-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  button#back-button {
    display: none;
  }

  button#back-button-mobile {
    display: block;
  }

  div#checkout-ticket {
    width: 90%;
    margin: 20px auto 0px auto;
  }

  button#order-button {
    width: 100%;
  }

  h5#checkout-prompt {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
}
