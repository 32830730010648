input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

body {
  background-color: rgb(216, 250, 196);
}

div.home-background {
  background-color: rgb(216, 250, 196);
  height: 100vh;
  padding: 0 5vw;
}

img#home-logo {
  height: 4vh;
  margin-top: 4vh;
}

h2.page-title {
  font-size: 40px;
  color: #05607c;
  margin-top: 5vh;
}

input {
  border: 2px solid #05607c8e;
  font-size: 18px;
  line-height: 22px;
  padding: 12px 0px 12px 6px;
  border-radius: 2px;
  display: block;
  font-family: "DM Sans", sans-serif;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  outline: none;
}

::placeholder {
  opacity: 0.4;
}

select {
  border: 2px solid #05607c8e;
  font-size: 16px;
  line-height: 22px;
  padding: 11px 0px 12px 6px;
  border-radius: 2px;
  display: block;
  font-family: "DM Sans", sans-serif;
  width: 100%;
  box-sizing: border-box;
}

select:focus {
  outline: none;
}

div.half-holder {
  width: 49%;
  display: inline-block;
  box-sizing: border-box;
  padding: 5px 0px;
}

div.full-holder {
  padding: 5px 0px;
}

div.line-container {
  display: flex;
  justify-content: space-between;
}

label {
  font-size: 12px;
  margin-bottom: 3px;
  display: block;
  font-family: "DM Sans", sans-serif;
}

button.primary {
  width: 70%;
  background-color: #47d581;
  color: white;
  font-size: 19px;
  font-weight: 700;
  border-radius: 16px;
  border: none;
  padding: 20px 0px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.17);
  margin-top: 3vh;
}

button.primary:hover {
  cursor: pointer;
}

button.primary:active {
  transform: scale(0.98);
}

button.primary:focus {
  outline: none;
}

button.secondary {
  width: 70%;
  border: 2px solid #47d581;
  color: #47d581;
  font-size: 19px;
  font-weight: 700;
  border-radius: 16px;
  padding: 20px 0px;
  margin-top: 3vh;
  background-color: white;
}

button.secondary:hover {
  cursor: pointer;
}

button.secondary:active {
  transform: scale(0.98);
}

button.secondary:focus {
  outline: none;
}

div.order-ticket {
  background-color: white;
  font-family: "DM Sans", sans-serif;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 20px 17px 5px 17px;
  margin-top: 2vh;
  color: #05607c;
  width: 70%;
  box-sizing: border-box;
}

div.order-ticket h3 {
  margin: 0px;
  font-size: 19px;
}

div.order-ticket table {
  margin-top: 2vh;
  color: #597983;
  font-weight: 700;
  font-size: 15px;
  border-collapse: collapse;
  width: 90%;
  font-weight: 500;
}

div.order-ticket .col1 {
  width: 10%;
  text-align: left;
  height: 25px;
}

div.order-ticket .col2 {
  width: 50%;
}

div.order-ticket .col3 {
  width: 20%;
  text-align: right;
}

div.order-ticket .col4 {
  width: 15%;
  text-align: right;
}

div.order-ticket #mini-total {
  font-size: 10px;
  font-weight: 700;
  border-top: 1px solid #3e565e;
}

div.order-ticket tr#total-line {
  height: 40px;
  text-align: left;
  border-top: 1px solid #3e565e;
  font-weight: 700;
}

div.order-ticket h5 {
  font-size: 12px;
  color: #4dbb3d;
  font-weight: 500;
  width: 90%;
  margin: 0px;
  line-height: 13px;
}

/* ------------------------------------------------   */

@media (max-width: 768px) {
  div.home-background {
    height: auto;
    padding: 0 5vw;
  }

  h2.page-title {
    font-size: 36px;
    line-height: 36px;
    margin-top: 5vh;
  }

  input {
    border: 2px solid #05607c8e;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 0px 12px 6px;
    border-radius: 2px;
    display: block;
    font-family: "DM Sans", sans-serif;
    width: 100%;
    box-sizing: border-box;
  }

  input:focus {
    outline: none;
  }

  ::placeholder {
    opacity: 0.4;
  }

  select {
    border: 2px solid #05607c8e;
    font-size: 16px;
    line-height: 22px;
    padding: 11px 0px 12px 6px;
    border-radius: 2px;
    display: block;
    font-family: "DM Sans", sans-serif;
    width: 100%;
    box-sizing: border-box;
  }

  select:focus {
    outline: none;
  }

  div.half-holder {
    width: 49%;
    display: inline-block;
    box-sizing: border-box;
    padding: 5px 0px;
  }

  div.full-holder {
    padding: 5px 0px;
  }

  div.line-container {
    display: flex;
    justify-content: space-between;
  }

  label {
    font-size: 12px;
    margin-bottom: 3px;
    display: block;
    font-family: "DM Sans", sans-serif;
  }

  button.primary {
    width: 100%;
    margin-top: 3vh;
  }

  #button-sms{
    width: 90%;
  }
  button.secondary {
    width: 100%;
    margin-top: 3vh;
  }

  div.order-ticket {
    padding: 20px 17px 5px 17px;
    margin-top: 2vh;
    color: #05607c;
    width: 100%;
  }

  div.order-ticket table {
    margin-top: 2vh;
    width: 100%;
  }

  div.order-ticket .col1 {
    width: 15%;
  }

  div.order-ticket .col2 {
    width: 50%;
  }

  div.order-ticket .col3 {
    width: 10%;
  }

  div.order-ticket .col4 {
    width: 25%;
  }

  div.order-ticket #mini-total {
    font-size: 12px;
  }

  div.order-ticket h5 {
    font-size: 12px;
    color: #4dbb3d;
    font-weight: 500;
    width: 90%;
    margin: 0px;
    line-height: 13px;
  }
}
