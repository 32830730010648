
.bubbleContainer{
  position: absolute;
  top: -36px;
  left: 2px;
  opacity: 0;
  pointer-events: none;
  z-index: 999999;
  -webkit-transition: opacity 0.6s ease-in-out;
  -moz-transition: opacity 0.6s ease-in-out;
  -ms-transition: opacity 0.6s ease-in-out;
  -o-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;
}

.opaque{
  opacity: 1;
}
.bubble{
  max-width: 150px;
  z-index: 999;
  position: absolute;
}

.bubbleLogo{
  max-width: 64px;
  z-index: 1000;
  position: absolute;
  left: 53px;
  top: 10px;
}

.bubbleDescription{
  position: absolute;
  top: 94px;
  left: 28px;
  width: 115px;
  text-align: center;
  height: 58px;
  opacity: 0.6;
  z-index: 1000;
  font-size: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;

}

