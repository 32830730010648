@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



div#home-split {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  height: 85vh;
}

div#left-split {
  width: 50%;
}

div#left-split h1 {
  font-family: "DM Sans", sans-serif;
  font-size: 46px;
  line-height: 55px;
  color: #05607c;
  margin-top: 5vh;
  margin-bottom: 0px;
}

div#left-split h4 {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  color: #9b9b9b;
  font-size: 19px;
  margin-top: 1vh;
  padding-right: 10vw;
  line-height: 24px;
  margin-bottom: 0px;
}

div#left-split ul {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #628871;
  margin: 0px;
  margin-top: 2vh;
}

div#left-split li {
  padding: 4px;
}

div#left-split span.important {
  font-weight: 700;
  color: #28573b;
  text-decoration: underline;
}

div#bill-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 50vh;
}

div#right-split {
  width: 50%;
}

table button.increment {
  background-color: rgb(170, 223, 202);
  display: inline;
  margin: 2px;
  border-radius: 10px;
  border: none;
  height: 20px;
  width: 20px;
}

table button.increment:focus {
  outline: none;
}

table button.increment:hover {
  cursor: pointer;
}

table button.increment:active {
  transform: scale(0.98);
}

h5#empty-cart {
  margin: 20px 0px;
  color: #05607cb0;
}

h5#prompter {
  color: rgb(230, 155, 44);
  font-size: 13px;
  font-weight: 500;
  width: 70%;
}

/* ------------------------------------------------   */

@media (max-width: 768px) {
  div#home-split {
    flex-direction: column-reverse;
    height: auto;
  }

  div#left-split {
    width: 100%;
  }

  div#left-split h1 {
    font-size: 36px;
    line-height: 37px;
    margin-top: 2vh;
  }

  div#left-split h4 {
    color: #9b9b9b;
    font-size: 17px;
    margin-top: 1vh;
    padding-right: 0vw;
  }

  div#left-split ul {
    font-size: 14px;
    margin: 0px;
    margin-top: 2vh;
    margin-bottom: 4vh;
  }

  div#left-split li {
    padding: 2px;
  }

  div#bill-center {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  table button.increment {
    background-color: rgb(170, 223, 202);
    display: inline;
    margin: 2%;
    border-radius: 5px;
    height: 20px;
    width: 45%;
  }

  h5#prompter {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }

  div#right-split {
    width: 100%;
    height: 900px;
  }
}

@media (max-width: 568px) {
  div#right-split {
    width: 100%;
    height: 500px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

body {
  background-color: rgb(216, 250, 196);
}

div.home-background {
  background-color: rgb(216, 250, 196);
  height: 100vh;
  padding: 0 5vw;
}

img#home-logo {
  height: 4vh;
  margin-top: 4vh;
}

h2.page-title {
  font-size: 40px;
  color: #05607c;
  margin-top: 5vh;
}

input {
  border: 2px solid #05607c8e;
  font-size: 18px;
  line-height: 22px;
  padding: 12px 0px 12px 6px;
  border-radius: 2px;
  display: block;
  font-family: "DM Sans", sans-serif;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  outline: none;
}

::-webkit-input-placeholder {
  opacity: 0.4;
}

:-ms-input-placeholder {
  opacity: 0.4;
}

::placeholder {
  opacity: 0.4;
}

select {
  border: 2px solid #05607c8e;
  font-size: 16px;
  line-height: 22px;
  padding: 11px 0px 12px 6px;
  border-radius: 2px;
  display: block;
  font-family: "DM Sans", sans-serif;
  width: 100%;
  box-sizing: border-box;
}

select:focus {
  outline: none;
}

div.half-holder {
  width: 49%;
  display: inline-block;
  box-sizing: border-box;
  padding: 5px 0px;
}

div.full-holder {
  padding: 5px 0px;
}

div.line-container {
  display: flex;
  justify-content: space-between;
}

label {
  font-size: 12px;
  margin-bottom: 3px;
  display: block;
  font-family: "DM Sans", sans-serif;
}

button.primary {
  width: 70%;
  background-color: #47d581;
  color: white;
  font-size: 19px;
  font-weight: 700;
  border-radius: 16px;
  border: none;
  padding: 20px 0px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.17);
  margin-top: 3vh;
}

button.primary:hover {
  cursor: pointer;
}

button.primary:active {
  transform: scale(0.98);
}

button.primary:focus {
  outline: none;
}

button.secondary {
  width: 70%;
  border: 2px solid #47d581;
  color: #47d581;
  font-size: 19px;
  font-weight: 700;
  border-radius: 16px;
  padding: 20px 0px;
  margin-top: 3vh;
  background-color: white;
}

button.secondary:hover {
  cursor: pointer;
}

button.secondary:active {
  transform: scale(0.98);
}

button.secondary:focus {
  outline: none;
}

div.order-ticket {
  background-color: white;
  font-family: "DM Sans", sans-serif;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 20px 17px 5px 17px;
  margin-top: 2vh;
  color: #05607c;
  width: 70%;
  box-sizing: border-box;
}

div.order-ticket h3 {
  margin: 0px;
  font-size: 19px;
}

div.order-ticket table {
  margin-top: 2vh;
  color: #597983;
  font-weight: 700;
  font-size: 15px;
  border-collapse: collapse;
  width: 90%;
  font-weight: 500;
}

div.order-ticket .col1 {
  width: 10%;
  text-align: left;
  height: 25px;
}

div.order-ticket .col2 {
  width: 50%;
}

div.order-ticket .col3 {
  width: 20%;
  text-align: right;
}

div.order-ticket .col4 {
  width: 15%;
  text-align: right;
}

div.order-ticket #mini-total {
  font-size: 10px;
  font-weight: 700;
  border-top: 1px solid #3e565e;
}

div.order-ticket tr#total-line {
  height: 40px;
  text-align: left;
  border-top: 1px solid #3e565e;
  font-weight: 700;
}

div.order-ticket h5 {
  font-size: 12px;
  color: #4dbb3d;
  font-weight: 500;
  width: 90%;
  margin: 0px;
  line-height: 13px;
}

/* ------------------------------------------------   */

@media (max-width: 768px) {
  div.home-background {
    height: auto;
    padding: 0 5vw;
  }

  h2.page-title {
    font-size: 36px;
    line-height: 36px;
    margin-top: 5vh;
  }

  input {
    border: 2px solid #05607c8e;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 0px 12px 6px;
    border-radius: 2px;
    display: block;
    font-family: "DM Sans", sans-serif;
    width: 100%;
    box-sizing: border-box;
  }

  input:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    opacity: 0.4;
  }

  :-ms-input-placeholder {
    opacity: 0.4;
  }

  ::placeholder {
    opacity: 0.4;
  }

  select {
    border: 2px solid #05607c8e;
    font-size: 16px;
    line-height: 22px;
    padding: 11px 0px 12px 6px;
    border-radius: 2px;
    display: block;
    font-family: "DM Sans", sans-serif;
    width: 100%;
    box-sizing: border-box;
  }

  select:focus {
    outline: none;
  }

  div.half-holder {
    width: 49%;
    display: inline-block;
    box-sizing: border-box;
    padding: 5px 0px;
  }

  div.full-holder {
    padding: 5px 0px;
  }

  div.line-container {
    display: flex;
    justify-content: space-between;
  }

  label {
    font-size: 12px;
    margin-bottom: 3px;
    display: block;
    font-family: "DM Sans", sans-serif;
  }

  button.primary {
    width: 100%;
    margin-top: 3vh;
  }

  #button-sms{
    width: 90%;
  }
  button.secondary {
    width: 100%;
    margin-top: 3vh;
  }

  div.order-ticket {
    padding: 20px 17px 5px 17px;
    margin-top: 2vh;
    color: #05607c;
    width: 100%;
  }

  div.order-ticket table {
    margin-top: 2vh;
    width: 100%;
  }

  div.order-ticket .col1 {
    width: 15%;
  }

  div.order-ticket .col2 {
    width: 50%;
  }

  div.order-ticket .col3 {
    width: 10%;
  }

  div.order-ticket .col4 {
    width: 25%;
  }

  div.order-ticket #mini-total {
    font-size: 12px;
  }

  div.order-ticket h5 {
    font-size: 12px;
    color: #4dbb3d;
    font-weight: 500;
    width: 90%;
    margin: 0px;
    line-height: 13px;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendingMachineContainer {
  width: 331px;
  height: 486px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.5);
}

#vitalayLogo {
  width: 80px;
  position: absolute;
  top: 13px;
}

.selectedProduct {
  width: 66px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 70px;
  left: 255px;
  z-index: 50;
}

.button {
  opacity: 1;
  width: 46px;
  height: 46px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d8fac4;
  border-radius: 50%;
  top: 140px;
  left: 265px;
  z-index: 50;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.led {
  width: 3px;
  height: 3px;
  position: absolute;
  background: #eb3410;
  border-radius: 50%;
}

.ledSelected {
  background: #96ff14;
}

.led1 {
  top: 74px;
  left: 59px;
}

.led2 {
  top: 74px;
  left: 140px;
}

.led3 {
  top: 154px;
  left: 59px;
}

.led4 {
  top: 154px;
  left: 140px;
}

.led5 {
  top: 234px;
  left: 59px;
}

.led6 {
  top: 234px;
  left: 140px;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  transform: scale(0.9);
}

.buttonText {
  padding-bottom: 7px;
  padding-left: 2px;

  font-size: 35px;
  color: #05607c;
  text-align: center;
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.textContainer {
  font-size: 15px;
  color: #96ff13;
  text-align: center;
  font-family: "VT323", sans-serif;
  line-height: 13px;
}

.bottle {
  width: 30px;
  position: absolute;
  z-index: 50;
  cursor: pointer;
  opacity: 0.8;
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.bottleSelected {
  opacity: 1;
}
.bottle:hover {
  opacity: 1;
}

.bottleDrop1 {
  animation: drop1 0.8s ease-out;
  -moz-animation: drop1 0.8s; /* Firefox */
  -webkit-animation: drop1 0.8s ease-in; /* Safari and Chrome */
}

@keyframes drop1 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(-30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}

.bottleDrop2 {
  animation: drop2 0.8s ease-out;
  -moz-animation: drop2 0.8s; /* Firefox */
  -webkit-animation: drop2 0.8s ease-in; /* Safari and Chrome */
}

@keyframes drop2 {
  0% {
    margin-top: 10px;
    opacity: 1;
    transform: rotate(0deg);
  }
  80% {
    margin-top: 300px;
    opacity: 1;
    transform: rotate(30deg);
  }
  81% {
    opacity: 0;
    padding-left: 0px;
    transform: rotate(0deg);
  }
  82% {
    margin-top: 10px;
    width: 25px;
    padding-left: 2.5px;
  }
  100% {
    width: 30px;
    opacity: 1;
    padding-left: 0px;
  }
}

.formosa {
  top: 10px;
  left: 45px;
  z-index: 99;
}

.bali {
  top: 10px;
  left: 126px;
  z-index: 99;
}
.formosa {
  top: 10px;
  left: 45px;
  z-index: 99;
}

.jasmin {
  top: 90px;
  left: 45px;
  z-index: 95;
}

.marocain {
  top: 90px;
  left: 126px;
  z-index: 95;
}

.rio {
  top: 170px;
  left: 45px;
  z-index: 90;
}

.limonade {
  top: 170px;
  left: 126px;
  z-index: 90;
}

.bottleArea {
  width: 200px;
  height: 261px;
  position: absolute;
  left: 22px;
  top: 74px;
  overflow: hidden;
}

#PUSH {
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.infoContainer{
  position: absolute;
  margin-top: 6px;
  margin-left: 35px;
}

.infoBox{
  max-width: 18px;
  opacity: 1;
  z-index: 9999999999999999;
  moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.infoBox:hover{
  opacity: 0.3;
}

/* ------------------------------------------------   */

@media (max-width: 768px) {
  .vendingMachineContainer {
    transform: scale(1.5);
  }
}

@media (max-width: 568px) {
  .vendingMachineContainer {
    transform: scale(0.9);
  }
}


.bubbleContainer{
  position: absolute;
  top: -36px;
  left: 2px;
  opacity: 0;
  pointer-events: none;
  z-index: 999999;
  transition: opacity 0.6s ease-in-out;
}

.opaque{
  opacity: 1;
}
.bubble{
  max-width: 150px;
  z-index: 999;
  position: absolute;
}

.bubbleLogo{
  max-width: 64px;
  z-index: 1000;
  position: absolute;
  left: 53px;
  top: 10px;
}

.bubbleDescription{
  position: absolute;
  top: 94px;
  left: 28px;
  width: 115px;
  text-align: center;
  height: 58px;
  opacity: 0.6;
  z-index: 1000;
  font-size: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;

}


div#form-holder {
  background-color: white;
  width: 75vw;
  padding: 35px;
  margin-bottom: 30px;
  display: flex;
  border-radius: 3px;
}

div#right-form {
  flex: 50 1;
}

div#left-form {
  flex: 50 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

div#ticket-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

button#back-button {
  width: 45%;
}

button#back-button-mobile {
  display: none;
}

div#checkout-ticket {
  width: 80%;
}

button#order-button {
  width: 80%;
}

h5#checkout-prompt {
  color: rgb(230, 155, 44);
  font-size: 13px;
  font-weight: 500;
  width: 70%;
  width: 80%;
}

/* ------------------------------------------------ */

@media (max-width: 768px) {
  div#form-holder {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    padding-bottom: 3vh;
    background-color: transparent;
  }

  div#ticket-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  button#back-button {
    display: none;
  }

  button#back-button-mobile {
    display: block;
  }

  div#checkout-ticket {
    width: 90%;
    margin: 20px auto 0px auto;
  }

  button#order-button {
    width: 100%;
  }

  h5#checkout-prompt {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
}

h2#sms-title {
  text-align: center;
}

div#sms-form-holder {
  width: 50vw;
  margin: 0px auto;
  background-color: white;
  padding: 4vh 0px;
}

div#sms-form-holder h5 {
  text-align: center;
  font-size: 22px;
  color: #28573b;
  font-weight: 500;
  margin: 0px auto;
  width: 80%;
}

input#smscode {
  border: none;
  border-bottom: 2px solid rgba(47, 79, 79, 0.466);
  width: 280px;
  display: block;
  margin: 0px auto;
  font-size: 55px;
  letter-spacing: 15px;
  text-align: center;
  margin-top: 5vh;
}

div#sms-form-holder button {
  margin: 0px auto;
  display: block;
  margin-top: 10vh;
}

div#sms-form-holder h5#sms-error {
  color: rgb(230, 155, 44);
  font-size: 15px;
  font-weight: 500;
  width: 80%;
  margin-top: 20px;
}

h3#success-message {
  font-family: "DM Sans", sans-serif;
  color: #28573b;
  width: 80%;
  margin: 0px auto;
}

/* ------------------------------------------------ */

@media (max-width: 768px) {
  div#sms-form-holder {
    width: 100%;
    border-radius: 3px;
  }
}

